import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import ProductCard from '../../components/card/card';

import { sliderImages, products } from '../../../utils/consts';
import { EDeviceForm } from '../../../utils/models';

import Logo from '../../../assets/img/aero-service-logo.png';
import IvanicsLogo from '../../../assets/img/ivanics_logo.png';
import LangLogo from '../../../assets/img/AKLogo.png';
import UnixLogo from '../../../assets/img/unix-logo1.png';
import LiquiMolyLogo from '../../../assets/img/liqui-moly_logo.png';

import styles from './home.module.scss';


interface HomeProps {
  device: EDeviceForm;
}

const Home: React.FC<HomeProps> = ({ device }) => {
	const isMobile = device === EDeviceForm.MOBILE_XS || device === EDeviceForm.MOBILE;

	return (
		<div className={styles.home}>
			<div className={styles.gallery}>
				<ImageGallery items={sliderImages}
					autoPlay 
					showPlayButton={false}
					showFullscreenButton={false}
					slideDuration={1000}
					slideInterval={3000}
					thumbnailPosition={isMobile ? 'bottom' : 'left'} />	
			</div>
			<div className={styles.logo_wrapper}>
				<Typography variant='h1' className={styles.pageTitle}>Aero Szerviz</Typography>
				{isMobile && <img src={Logo} className={styles.logo} alt='aero service logo'/>}
			</div>
			<Typography variant='h2' className={styles.pageSubtitle}>Szolgáltatásaink</Typography>
			<div className={styles.product_wrapper} >
				{products.map((product, i) => <ProductCard key={`${product.title}${i}`} product={product}/>)}
			</div>
			<div className={styles.toldAboutUs}>
				<Typography variant='h2' className={styles.pageSubtitle}>Rólunk mondták</Typography>
				<div className={styles.quoteWrapper}>
					<div className={styles.quote}>
						<Typography variant='overline' className={styles.quoteText}>&quot;Sok-sok éve már, hogy ide hozom autóinkat szervizelni és soha nem csalódtam. Szakmailag, emberileg és anyagilag is, minden javítási munka előzetesen megbeszélésre kerül.&quot; </Typography>
						<Typography align='right'>- Erdélyi Gyula -</Typography>
					</div>
					<div className={styles.quote}>
						<Typography variant='overline' className={styles.quoteText}>&quot;Precizitás, minőségi munka végzés, korrekt árak és megrendelő központúság jellemzi az Aero Service csapatát.
					Eszembe se jutna hogy másra bízzam az autóim szervízelését és javítását.&quot; </Typography>
						<Typography align='right'>- Pál Sándor -</Typography>
					</div>
					<div className={styles.quote}>
						<Typography variant='overline' className={styles.quoteText}>&quot;Kiválló szakmai hozzaértés és precíz, korrekt munka jellemzi. 
					A megbeszélt időpontokat pontosan betartja, csak ajánlani tudom mindenkinek. &quot; </Typography>
						<Typography align='right'>- Bányai Sándor -</Typography>
					</div>
				</div>
			</div>
			<div className={styles.partnersWrapper}>
				<Typography variant='h2' className={styles.pageSubtitle}>Partnereink</Typography>
				<div className={styles.partnerLogoWrapper}>
					<div className={styles.logoContainer}>
						<img className={styles.logo} src={LangLogo} alt="Lang autolkatresz logo" />
					</div>
					<div className={styles.logoContainer}>
						<img className={styles.logo} src={IvanicsLogo} alt="Ivanics csoport logo" />
					</div>
					<div className={styles.logoContainer}>
						<img className={styles.logo} src={UnixLogo} alt="Unix autolkatresz logo" />
					</div>
					<div className={styles.logoContainer}>
						<img className={styles.logo} src={LiquiMolyLogo} alt="Liqui Moly logo" />
					</div>
				</div>
			</div>
			<footer className={styles.footer}>
				<Typography className={styles.footerText} align='left' variant='body2'>Aero Szerviz 2022 © Minden jog fenntartva.</Typography>
				<Link to='/adatvedelem' className={styles.footerLink}>Adatvédelem</Link>
			</footer>
		</div>
	);
};
	
export default Home;
