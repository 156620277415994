import React from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import styles from './datasecurity.module.scss';

const DataSecurity = (): JSX.Element => {
	return (
		<section className={styles.datasecurity}>
			<Box>
				<Typography variant='h1' className={styles.pageTitle} >Adatvédelem</Typography>
				<Typography variant='body2'>Az oldalon közölt tartalmak az Aero Szerviz tulajdonosának, üzemeltetőjének szellemi termékei. 
				Az üzemeltető, tulajdonos ezúton hozzájárulását adja a honlapon közzétett információk idézéséhez, abban az esetben ha betartják az általános törvényi követelményeket, 
				valamint hivatkozásul feltüntetik a  www.aeroszerviz.hu címre. A tulajdonos és üzemeltető nem vállal garanciát a honlap tartalmának pontosságáért vagy teljességéért. 
				Kifejezetten visszautasítja a felelősséget a honlapon esetlegesen megjelenő hiba vagy hiányosság miatt, illetve a honlap használatából adódó károk miatt.</Typography>
				<Typography variant='body2'>Weboldalunk használatával elfogadja a felhasználási feltételeket.</Typography>
				<Typography variant='h2' className={styles.pageSubtitle}>Adatvédelmi nyilatkozat</Typography>
				<Typography variant='body2'>Jelen adatkezelési tájékoztató Makó István ev., mint Adatkezelő személyes adatokkal kapcsolatba hozható adatkezelését szabályozza.</Typography>
				<Typography variant='body2'>Adatainak védelme fontos számunkra!</Typography>
				<Typography variant='body2'>Tiszteletben tartjuk weboldal látogatóink, szolgáltatásaink iránt érdeklődők személyes adatainak, magánéletének védelmét, ezért kizárólag olyan információkat gyűjtünk és kezelünk, amelyek jelen tájékoztatóban meghatározott célok szempontjából elengedhetetlenek.</Typography>
				<Typography variant='body2'>Kérjük figyelmesen olvassa el a Tájékoztatót, annak érdekében, hogy megértse, hogy hogyan kezeljük a személyes adatait és megismerje az adatkezeléssel kapcsolatos velünk szemben is érvényesíthető jogait. (Az érintetti jogokat a GDPR 12-23. cikkei tartalmazzák.) Igyekeztünk a jelen tájékoztatót tömör, átlátható formában, világosan és közérthetően megfogalmazni. Ha a természetes személy adatok kezelésével, illetve egyéb kapcsolódó témában mégis kérdése lenne, úgy kérjük, hogy keressen minket bizalommal a lenti elérhetőségek bármelyikén.</Typography>
				<Typography variant='body2'>Cégünk a működése, tevékenységellátása bármely területén (termékértékesítés, szolgáltatásnyújtás stb.) rendelkezésére bocsátott vagy egyéb módon tudomására jutott személyes adatokat bizalmasan kezeli és a biztonságos adatkezelést elősegítő minden szükséges intézkedést megtesz annak érdekében, hogy adatkezelése során a kötelezően alkalmazandó európai uniós jogi aktusokban foglaltaknak, a hatályos magyar jogszabályok rendelkezéseinek, valamint az adatvédelem terén kialakuló, illetve kialakult gyakorlatnak megfelelően járjon el.</Typography>
				<Typography variant='body2'>A weboldalunk használata és szolgáltatásaink igénybe vétele teljesen önkéntes, így az ehhez esetlegesen szükséges személyes adatok kezelése is önkéntes, hozzájárulás alapon kerülnek hozzánk, azokat amennyiben szükséges, csakis ezután fogjuk szerződés teljesítése, illetve jogszabályi kötelezettség teljesítése jogalapokon is kezelni.</Typography>
				<Typography variant='body2'>Jelen adatkezelési tájékoztató az alábbi oldalak adatkezelését szabályozza:</Typography>
				<Typography variant='body2'>www.aeroszerviz.hu</Typography>
				<Typography variant='body2'>Az adatkezelési tájékoztató elérhető az alábbi oldalról:</Typography>
				<Link to='/adatvedelem'>www.aeroszerviz.hu/adatvedelem</Link>
				<Typography variant='body2'>A tájékoztató módosításai a fenti címen történő közzététellel lépnek hatályba.</Typography>
				<Typography variant='body2'>Az adatkezelő és elérhetőségei:</Typography>
				<Typography variant='body2'>Postai cím: 4028 Debrecen, Kassai ut 13.</Typography>
				<Typography variant='body2'>Telefon: +36 30 93 66 888</Typography>
				<Typography variant='body2'>Email: mako.istvan.aero@gmail.com</Typography>
				<Typography variant='body2'>Megkeresésére késedelem nélkül, de legkésőbb a kérelem beérkezésétől számított 1 hónapon belül válaszolunk, hacsak a jogi feltételek a válaszadásra 2 hónapos hosszabbítást lehetővé nem teszik. A határidő meghosszabbításáról az adatkezelő a késedelem okainak megjelölésével a kérelem kézhezvételétől számított 1 hónapon belül tájékoztatja Önt.</Typography>
				<Typography variant='body2'>Ha az adatkezelő nem tesz intézkedéseket Ön kérelme nyomán, késedelem nélkül, de legkésőbb a kérelem beérkezésétől számított egy hónapon belül tájékoztatja Önt az intézkedés elmaradásának okairól, valamint arról, hogy Ön panaszt nyújthat be valamely felügyeleti hatóságnál, és élhet bírósági jogorvoslati jogával.</Typography>
				<Typography variant='h2' className={styles.pageSubtitle}>Cégünk tevékenységi köre</Typography>
				<Typography variant='body2'>!!!!Tevekenysegi kör !!!</Typography>
				<Typography variant='body2'>Mint Adatkezelő betartjuk a jogszabályi előírásokat, a személyes adatokat kizárólag jogszabályban meghatározott jogalappal kezeljük, betartjuk a személyes adatok kezelésére vonatkozó alapelveket. Ezeket a jogalapokat és elveket pár fogalom meghatározásával együtt adatkezelési tájékoztatónkban összegyűjtöttük, hogy itt is tájékozódhasson ezekről.</Typography>
				<Typography variant='h2' className={styles.pageSubtitle}>Irányadó jogszabályok</Typography>
				<Typography variant='body2'>A  jelen Adatvédelmi Tájékoztató értelmezésére a hatályos uniós és magyar jog az irányadó.</Typography>
				<Typography variant='body2'></Typography>
				<List subheader="Az adatkezelési tájékoztató rendelkezéseinek kialakításakor figyelembe vettük az alábbi jogszabályok rendelkezéseit:" className={styles.list}>
					<ListItem >
						<ListItemText className={styles.listItem} id="1" primary="Az Európai Parlament és a Tanács 2016/679 Rendelete („Általános Adatvédelmi Rendelet” vagy „GDPR”)" />
					</ListItem>
					<ListItem >
						<ListItemText className={styles.listItem} id="2" primary="Az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény („Infotv.”)" />
					</ListItem>
					<ListItem >
						<ListItemText className={styles.listItem} id="3" primary="A Polgári Törvénykönyvről szóló 2013. évi V. törvény („Ptk.”)" />
					</ListItem>
					<ListItem >
						<ListItemText className={styles.listItem} id="4" primary="A gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008. évi XLVIII. törvény („Grtv.”)" />
					</ListItem>
					<ListItem >
						<ListItemText className={styles.listItem} id="5" primary="Az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalmi szolgáltatások egyes kérdéseiről szóló CVIII. törvény" />
					</ListItem>
					<ListItem>
						<ListItemText className={styles.listItem} id="6" primary="évi LXIII. törvény a személyes adatok védelméről és a közérdekű adatok nyilvánosságáról" />
					</ListItem>
				</List>
				<Typography variant='body2'>A tájékoztató összeállításakor figyelemmel voltunk továbbá a Nemzeti Adatvédelmi és Információszabadság Hatóság az előzetes tájékoztatás adatvédelmi követelményeiről szóló ajánlására.</Typography>
				<Typography variant='h2' className={styles.pageSubtitle}>Fogalom meghatározások</Typography>
				<Typography variant='body2'>„személyes adat”: azonosított vagy azonosítható természetes személyre („érintett”) vonatkozó bármely információ; azonosítható az a természetes személy, aki közvetlen vagy közvetett módon, különösen valamely azonosító, például név, szám, helymeghatározó adat, online azonosító vagy a természetes személy testi, fiziológiai, genetikai, szellemi, gazdasági, kulturális vagy szociális azonosságára vonatkozó egy vagy több tényező alapján azonosítható;</Typography>
				<Typography variant='body2'>„adatkezelés”: a személyes adatokon vagy adatállományokon automatizált vagy nem automatizált módon végzett bármely művelet vagy műveletek összessége, így a gyűjtés, rögzítés, rendszerezés, tagolás, tárolás, átalakítás vagy megváltoztatás, lekérdezés, betekintés, felhasználás, közlés továbbítás, terjesztés vagy egyéb módon történő hozzáférhetővé tétel útján, összehangolás vagy összekapcsolás, korlátozás, törlés, illetve megsemmisítés;</Typography>
				<Typography variant='body2'>„adatkezelő”: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely a személyes adatok kezelésének céljait és eszközeit önállóan vagy másokkal együtt meghatározza; ha az adatkezelés céljait és eszközeit az uniós vagy a tagállami jog határozza meg, az adatkezelőt vagy az adatkezelő kijelölésére vonatkozó különös szempontokat az uniós vagy a tagállami jog is meghatározhatja;</Typography>
				<Typography variant='body2'>„adatfeldolgozó”: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely az adatkezelő nevében személyes adatokat kezel;</Typography>
				<Typography variant='body2'>„címzett”: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, akivel vagy amellyel a személyes adatot közlik, függetlenül attól, hogy harmadik fél-e. Azon közhatalmi szervek, amelyek egy egyedi vizsgálat keretében az uniós vagy a tagállami joggal összhangban férhetnek hozzá személyes adatokhoz, nem minősülnek címzettnek; az említett adatok e közhatalmi szervek általi kezelése meg kell, hogy feleljen az adatkezelés céljainak megfelelően az alkalmazandó adatvédelmi szabályoknak;</Typography>
				<Typography variant='body2'>„harmadik fél”: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely nem azonos az érintettel, az adatkezelővel, az adatfeldolgozóval vagy azokkal a személyekkel, akik az adatkezelő vagy adatfeldolgozó közvetlen irányítása alatt a személyes adatok kezelésére felhatalmazást kaptak;</Typography>
				<Typography variant='body2'>„az érintett hozzájárulása”: az érintett akaratának önkéntes, konkrét és megfelelő tájékoztatáson alapuló és egyértelmű kinyilvánítása, amellyel az érintett nyilatkozat vagy a megerősítést félreérthetetlenül kifejező cselekedet útján jelzi, hogy beleegyezését adja az őt érintő személyes adatok kezeléséhez;</Typography>
				<Typography variant='body2'>„adatvédelmi incidens”: a biztonság olyan sérülése, amely a továbbított, tárolt vagy más módon kezelt személyes adatok véletlen vagy jogellenes megsemmisítését, elvesztését, megváltoztatását, jogosulatlan közlését vagy az azokhoz való jogosulatlan hozzáférést eredményezi.</Typography>
				<Typography variant='h2' className={styles.pageSubtitle}>A személyes adatok kezelésére vonatkozó elvek</Typography>
				<List subheader="A személyes adatok" className={styles.list}>
					<ListItem >
						<ListItemText className={styles.listItem} id="1" primary="kezelését jogszerűen és tisztességesen, valamint az érintett számára átlátható módon kell végezni („jogszerűség, tisztességes eljárás és átláthatóság”);" />
					</ListItem>
					<ListItem >
						<ListItemText className={styles.listItem} id="2" primary="gyűjtése csak meghatározott, egyértelmű és jogszerű célból történjen, és azokat ne kezeljék ezekkel a célokkal össze nem egyeztethető módon; nem minősül az eredeti céllal össze nem egyeztethetőnek a közérdekű archiválás céljából, tudományos és történelmi kutatási célból vagy statisztikai célból történő további adatkezelés („célhoz kötöttség”);" />
					</ListItem>
					<ListItem >
						<ListItemText className={styles.listItem} id="3" primary="az adatkezelés céljai szempontjából megfelelőek és relevánsak kell, hogy legyenek, és a szükségesre kell korlátozódniuk („adattakarékosság”);" />
					</ListItem>
					<ListItem >
						<ListItemText className={styles.listItem} id="4" primary="pontosnak és szükség esetén naprakésznek kell lenniük; minden észszerű intézkedést meg kell tenni annak érdekében, hogy az adatkezelés céljai szempontjából pontatlan személyes adatokat haladéktalanul töröljék vagy helyesbítsék („pontosság”);" />
					</ListItem>
					<ListItem >
						<ListItemText className={styles.listItem} id="5" primary="tárolásának olyan formában kell történnie, amely az érintettek azonosítását csak a személyes adatok kezelése céljainak eléréséhez szükséges ideig teszi lehetővé; a személyes adatok ennél hosszabb ideig történő tárolására csak akkor kerülhet sor, amennyiben a személyes adatok kezelésére közérdekű archiválás céljából, tudományos és történelmi kutatási célból vagy statisztikai célból kerül majd sor, az e rendeletben az érintettek jogainak és szabadságainak védelme érdekében előírt megfelelő technikai és szervezési intézkedések végrehajtására is figyelemmel („korlátozott tárolhatóság”);" />
					</ListItem>
					<ListItem>
						<ListItemText className={styles.listItem} id="6" primary="kezelését oly módon kell végezni, hogy megfelelő technikai vagy szervezési intézkedések alkalmazásával biztosítva legyen a személyes adatok megfelelő biztonsága, az adatok jogosulatlan vagy jogellenes kezelésével, véletlen elvesztésével, megsemmisítésével vagy károsodásával szembeni védelmet is ideértve („integritás és bizalmas jelleg”)." />
					</ListItem>
				</List>
				<Typography variant='body2'>Az adatkezelő felelős a fentiek megfelelésért, továbbá képesnek kell lennie e megfelelés igazolására („elszámoltathatóság”).</Typography>
				<Typography variant='h2' className={styles.pageSubtitle}>Adatkezelés jogalapja</Typography>
				<Typography variant='body2'>A személyes adatok kezelése kizárólag akkor és annyiban jogszerű, amennyiben legalább az alábbiak egyike teljesül:</Typography>
				<List subheader="A személyes adatok" className={styles.list}>
					<ListItem >
						<ListItemText className={styles.listItem} id="1" primary="az érintett hozzájárulását adta személyes adatainak egy vagy több konkrét célból történő kezeléséhez;" />
					</ListItem>
					<ListItem >
						<ListItemText className={styles.listItem} id="2" primary="az adatkezelés olyan szerződés teljesítéséhez szükséges, amelyben az érintett az egyik fél, vagy az a szerződés megkötését megelőzően az érintett kérésére történő lépések megtételéhez szükséges;" />
					</ListItem>
					<ListItem >
						<ListItemText className={styles.listItem} id="3" primary="az adatkezelés az adatkezelőre vonatkozó jogi kötelezettség teljesítéséhez szükséges;" />
					</ListItem>
					<ListItem >
						<ListItemText className={styles.listItem} id="4" primary="az adatkezelés az érintett vagy egy másik természetes személy létfontosságú érdekeinek védelme miatt szükséges;" />
					</ListItem>
					<ListItem >
						<ListItemText className={styles.listItem} id="5" primary="az adatkezelés közérdekű vagy az adatkezelőre ruházott közhatalmi jogosítvány gyakorlásának keretében végzett feladat végrehajtásához szükséges;" />
					</ListItem>
					<ListItem>
						<ListItemText className={styles.listItem} id="6" primary="az adatkezelés az adatkezelő vagy egy harmadik fél jogos érdekeinek érvényesítéséhez szükséges, kivéve, ha ezen érdekekkel szemben elsőbbséget élveznek az érintett olyan érdekei vagy alapvető jogai és szabadságai, amelyek személyes adatok védelmét teszik szükségessé, különösen, ha az érintett gyermek." />
					</ListItem>
				</List>

				<Typography variant='h2' className={styles.pageSubtitle}>Adatkezelések</Typography>
				<Typography variant='body2'>Weboldalunk felkeresése: Érintettek köre a weboldal látogatói. </Typography>
				<Typography variant='body2'>Kezelt adatok köre: A honlap megtekintése során automatikusan rögzítésre kerül a felhasználó látogatásának kezdő és befejező időpontja, illetve egyes esetekben - a felhasználó számítógépének beállításától függően - a böngésző és az operációs rendszer típusa. Ezen adatokból a rendszer automatikusan statisztikai adatokat generál. Az üzemeltető ezen adatokat nem kapcsolja össze személyes adatokkal.</Typography>
				<Typography variant='body2'>Adatkezelés célja: A látogatás időpontja, valamint a böngésző és operációs rendszer típusának felvétele és tárolása kizárólag statisztikai célokat szolgál. Az adatkezelés, a weboldal szolgáltatásait igénybe vevő  felhasználók és az adatkezelő közötti  kapcsolatot szolgálja Az adatkezelő a megjelölt céloktól eltérő célra a személyes adatokat nem használja. Az adatkezelés jogalapját a GDPR 6. cikk (1) bekezdés f) pontja biztosítja (jogos érdek). Jogos érdekünk, hogy óvjuk weboldalunkat és javítsuk szolgáltatásainkat, a weboldal rendszerbiztonságát és stabilitását értékelni tudjuk. Mindazonáltal technikai beállításaink során arra törekszünk (pl.: IP cím anonimizálás), hogy az oldal felkeresésével együtt járó adatkezelésnek az érintettre a legminimálisabb hatása legyen.</Typography>
				<Typography variant='body2'>Adatgyűjtés jogalapja: Az így megadott adatok kezelése a felhasználó önkéntes hozzájárulásával történik..</Typography>

				<Typography variant='h2' className={styles.pageSubtitle}>Ügyfélkapcsolatok és egyéb adatkezelések</Typography>
				<Typography variant='body2'>Amennyiben szolgáltatásaink igénybe vétele során kérdés merülne fel, esetleg probléma lenne, a honlapon megadott módokon (telefon, e-mail, közösségi oldalak stb.) kapcsolatba léphet velünk.</Typography>
				<Typography variant='body2'>A beérkezett e-maileket, üzeneteket, telefonokat, Facebook-on stb. megadott adatokat, a nevet és e-mail címet, valamint más, önként megadott személyes adataival együtt, az adatközléstől számított legfeljebb 2 év elteltével töröljük.</Typography>
				<Typography variant='body2'>Szolgáltatásunk során előfordulhat, hogy jelen tájékoztatóban fel nem sorolt adatkezelések fordulnak elő. Ilyen esetben az adat felvételekor tájékoztatást adunk írásban - kivételes esetben szóban - arról, hogy az éppen aktuális adatfelvétel során miként, milyen célból, milyen alapon fogjuk adatait kezelni, meddig őrizzük meg és milyen jogai vannak az adatkezeléssel kapcsolatban.</Typography>
				<Typography variant='body2'>Kivételes hatósági megkeresésre, illetőleg jogszabály felhatalmazása alapján más szervek megkeresése esetén kötelesek vagyunk a tájékoztatás megadására, adatok közlésére, átadására, illetőleg iratok rendelkezésre bocsátására. Ezen esetekben a megkereső részére - amennyiben az a pontos célt és az adatok körét megjelölte - személyes adatot csak annyit és olyan mértékben adunk ki, amely a megkeresés céljának megvalósításához elengedhetetlenül szükséges.</Typography>

				<Typography variant='h2' className={styles.pageSubtitle}>Technikai célokat szolgáló adatok</Typography>
				<Typography variant='body2'>Érintettek köre: A weboldalt használó valamennyi érintett.</Typography>
				<Typography variant='body2'>Kezelt adatok köre: Az érintett számítógépének dinamikus IP címe, az ügyfél számítógépének beállításaitól függően az érintett által használt számítógép operációsrendszerének és böngészőjének típusa, az érintettnek a weboldallal kapcsolatos aktivitása.</Typography>
				<Typography variant='body2'>Az adatgyűjtés jogalapja: önkéntes hozzájárulás. A weboldal használat megkezdése előtt az adatkezelési szabályzat megismerése</Typography>
				<Typography variant='body2'>Adatkezelés célja: A weboldalon az automatikusan rögzítésre kerülő adatok (naplóállományok, logfile-ok) célja statisztika készítés, az informatikai rendszer technikai fejlesztése, a felhasználók jogainak védelme.</Typography>
				<Typography variant='body2'>Az Érintett adatait kizárólag számítástechnikai eszközzel végrehajtott adatfeldolgozással kezeljük.</Typography>
				<Typography variant='body2'>Ezen adatok felhasználása egyrészről technikai célokat szolgál - pl. a szerverek biztonságos üzemeltetése, utólagos ellenőrzése, másrészt az Adatkezelő ezen adatokat oldalhasználati statisztikák készítéséhez, a felhasználói igények elemzéséhez használja fel a szolgáltatások színvonalának emelése érdekében. A fenti adatok az érinettek azonosítására nem alkalmasak, és ezeket az Adatkezelő egyéb személyes adatokkal nem kapcsolja össze.</Typography>
				
				<Typography variant='h2' className={styles.pageSubtitle}>Kezelt adatok köre: a megadott adatok megfelelőssége</Typography>
				<Typography variant='body2'>Adatkezelő a neki megadott személyes adatokat nem ellenőrzi. A megadott adatok megfelelőségéért kizárólag az azt megadó személy felel. Bármely Érintett e-mail címének megadásakor egyben felelősséget vállal azért, hogy a megadott e-mail címről kizárólag ő veszi igénybe a szolgáltatást. E felelősségvállalásra tekintettel egy megadott e-mail címen történt belépésekkel összefüggő mindennemű felelősség kizárólag azt a felhasználót terheli, aki az e-mail címet regisztrálta.</Typography>
				<Typography variant='body2'>A személyes adataidat munkatársaink kezelhetik, valamint azok az adatfeldolgozók, akik velünk, mint adatkezelővel írásbeli adatfeldolgozói szerződést kötöttek. Adataidat mind munkatársaink, mind adatfeldolgozóink és az ő közvetlen munkatársai kizárólag az adatkezelés céljának eléréséhez szükséges mértékben és a jogalap biztosította ideig kezelhetik.</Typography>
				<Typography variant='body2'>Az adatfeldolgozók önálló döntést nem hoznak, kizárólag velünk, mint Adatkezelővel kötött szerződés és a kapott utasítások szerint jogosultak eljárni. Mint Adatkezelők ellenőrizzük az adatfeldolgozóink munkáját. Az adatfeldolgozók további adatfeldolgozót csak hozzájárulásunkkal jogosultak igénybe venni.</Typography>
				
				<Typography variant='h2' className={styles.pageSubtitle}>Kezelt adatok köre: a megadott adatok megfelelőssége</Typography>
				<Typography variant='body2'>Adatfeldolgozó által ellátott tevékenység: Tárhely-szolgáltatás</Typography>
				<Typography variant='body2'>Adatfeldolgozó megnevezése és elérhetősége:</Typography>
				<Typography variant='body2'>Cégnév: Websupport Magyarország Kft.</Typography>
				<Typography variant='body2'>Cím: 1132 Budapest, Victor Hugo utca 18-22.</Typography>
				<Typography variant='body2'>E-mail: info@tarhelypark.hu</Typography>
				<Typography variant='body2'>Telefon: +36 1 700 4140</Typography>
				<Typography variant='body2'>Az adatkezelés ténye, a kezelt adatok köre: Az érintett által megadott valamennyi személyes adat.</Typography>
				<Typography variant='body2'>Az érintettek köre: A weboldalt használó valamennyi érintett.</Typography>
				<Typography variant='body2'>Az adatkezelés célja: A weboldal elérhetővé tétele, megfelelő működtetése.</Typography>
				<Typography variant='body2'>Az adatkezelés időtartama, az adatok törlésének határideje: Az adatkezelő és a tárhely-szolgáltató közötti megállapodás megszűnéséig, vagy az érintettnek a tárhely-szolgáltató felé intézett törlési kérelméig tart az adatkezelés.</Typography>
				<Typography variant='body2'>Az adatfeldolgozás jogalapja: a Felhasználó hozzájárulása, az Infotv. 5. § (1) bekezdése, 6. cikk (1) bekezdés a) pontja, illetve az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény 13/A. § (3) bekezdése.</Typography>
				
				<Typography variant='h2' className={styles.pageSubtitle}>További adatkezelők:</Typography>
				<Typography variant='body2'>Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA) -statisztikai szolgáltatások</Typography>
				<Typography variant='h2' className={styles.pageSubtitle}>Cookie-k (sütik) kezelése</Typography>
				<Typography variant='body2'>Mi is használunk cookiekat szolgáltatásaink minőségének javítására, hogy a felhasználói élményt javítsuk és hogy minél relevánsabb, az érdeklődési körének megfelelő hirdetést jeleníthessünk meg, viselkedés alapú marketing futtatásával.</Typography>
				<Typography variant='body2'>Kezelt adatok köre: egyedi azonosítószám, dátumok, időpontok</Typography>
				<Typography variant='body2'>Érintettek köre: A weboldalt látogató valamennyi érintett.</Typography>
				<Typography variant='body2'>Adatkezelés célja: Felhasználók azonosítása és a látogatók nyomon követése.</Typography>
				<Typography variant='body2'>Adatkezelés időtartama: A vonatkozó látogatói munkamenet lezárásáig tartó időszak.</Typography>
				<Typography variant='body2'>Az adatkezelés jogalapja: az Infotv. 5. § (1) bekezdése, 6. cikk (1) bekezdés a) pontja, illetve az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény 13/A. § (3) bekezdése. Az érintettől hozzájárulás nem szükséges, amennyiben a cookie-k használatának kizárólagos célja az elektronikus hírközlő hálózaton keresztül történő közléstovábbítás vagy arra az előfizető vagy felhasználó által kifejezetten kért, az információs társadalommal összefüggő szolgáltatás nyújtásához a szolgáltatónak feltétlenül szüksége van.</Typography>
				<Typography variant='body2'>Az Adatkezelő rendszere automatikusan rögzíti a felhasználó számítógépének IP-címét, a látogatás kezdő időpontját, illetve egyes esetekben - a számítógép beállításától függően - a böngésző és az operációs rendszer típusát. Az így rögzített adatok egyéb személyes adatokkal nem kapcsolhatók össze. Az adatok kezelése kizárólag statisztikai célokat szolgál.</Typography>
				<Typography variant='body2'>A cookie-k lehetővé teszik a weboldal számára, hogy felismerje a korábbi látogatókat. A cookie-k segítenek a weboldal optimalizálásában, abban, hogy a weboldal szolgáltatásait a felhasználók szokásának megfelelően alakítsuk ki. A cookie-k alkalmasak továbbá arra, hogy:</Typography>
				<List subheader="A személyes adatok" className={styles.list}>
					<ListItem >
						<ListItemText className={styles.listItem} id="1" primary="megjegyezzék a beállításokat, így nem kell azokat a felhasználónak újra rögzítenie, ha egy új oldalra lép," />
					</ListItem>
					<ListItem >
						<ListItemText className={styles.listItem} id="2" primary="emlékeznek a korábban bevitt adatokra, ezért azokat nem kell újra begépelni," />
					</ListItem>
					<ListItem >
						<ListItemText className={styles.listItem} id="3" primary="elemzik a honlap használatát annak érdekében, hogy az így nyert információk felhasználásával végre hajtott fejlesztések eredményeként az a lehető legnagyobb mértékben a felhasználó elvárásai szerint működjön, a felhasználó könnyen megtalálja a keresett információt, és" />
					</ListItem>
					<ListItem >
						<ListItemText className={styles.listItem} id="4" primary="figyelemmel kísérik hirdetéseink hatékonyságát." />
					</ListItem>
				</List>
				<Typography variant='body2'>Mint Adatkezelő a cookie-kat felhasználjuk arra, hogy  a Felhasználók felé a Google és a Facebook útján reklámokat jelenítsünk meg. Az adatkezelés emberi beavatkozás nélkül történik.</Typography>
				
				
				<Typography variant='h2' className={styles.pageSubtitle}>A Google Analytics alkalmazása</Typography>
				<Typography variant='body2'>Ez a weboldal a Google Analytics alkalmazást használja, amely a Google Inc. („Google”) webelemző szolgáltatása. A Google Analytics úgynevezett „cookie-kat”, szövegfájlokat használ, amelyeket a számítógépére mentenek, így elősegítik Felhasználó által látogatott weblap használatának elemzését.</Typography>
				<Typography variant='body2'>A Felhasználó által használt weboldallal kapcsolatos cookie-kkal létrehozott információk rendszerint a Google egyik USA-beli szerverére kerülnek és tárolódnak. Az IP-anonimizálás weboldali aktiválásával a Google a Felhasználó IP-címét az Európai Unió tagállamain belül vagy az Európai Gazdasági Térségről szóló megállapodásban részes más államokban előzőleg megrövidíti.</Typography>
				<Typography variant='body2'>A teljes IP-címnek a Google USA-ban lévő szerverére történő továbbítására és ottani lerövidítésére csak kivételes esetekben kerül sor. A weboldalunk üzemeltetőjének megbízásából a Google ezeket az információkat arra fogja használni, hogy kiértékelje, hogyan használta a Felhasználó a honlapot, továbbá, hogy a weboldal üzemeltetőjének a honlap aktivitásával összefüggő jelentéseket készítsen, valamint, hogy a weboldal- és az internethasználattal kapcsolatos további szolgáltatásokat teljesítsen.</Typography>
				<Typography variant='body2'>A Google Analytics keretein belül a Felhasználó böngészője által továbbított IP-címet nem vezeti össze a Google más adataival. A cookie-k tárolását a Felhasználó a böngészőjének megfelelő beállításával megakadályozhatja, azonban felhívjuk figyelmét, hogy ebben az esetben előfordulhat, hogy a weboldalunk nem minden funkciója lesz teljes körűen használható. Megakadályozhatja továbbá, hogy a Google gyűjtse és feldolgozza a cookie-k általi, a Felhasználó weboldalhasználattal kapcsolatos adatait (beleértve az IP-címet is), ha letölti és telepíti az elérhető böngésző plugint.</Typography>
				
				<Typography variant='h2' className={styles.pageSubtitle}>Adatvédelmi incidens bejelentése a hatóságnak</Typography>
				<Typography variant='body2'>Az adatvédelmi incidenst az adatkezelő indokolatlan késedelem nélkül, és ha lehetséges, legkésőbb 72 órával azután, hogy az adatvédelmi incidens a tudomására jutott, bejelenti az illetékes felügyeleti hatóságnak, kivéve, ha az adatvédelmi incidens valószínűsíthetően nem jár kockázattal a természetes személyek jogaira és szabadságaira nézve. Ha a bejelentés nem történik meg 72 órán belül, mellékelni kell hozzá a késedelem igazolására szolgáló indokokat is.</Typography>
				<Typography variant='h2' className={styles.pageSubtitle}>Panasztételi lehetőség</Typography>
				<Typography variant='body2'>Amennyiben azt tapasztalja, hogy valamely jogát megsértettük, lépjen velünk kapcsolatba, mindent meg fogunk tenni az eset kivizsgálása érdekében, és hogy kárpótoljunk a kellemetlenségekért.</Typography>
				<Typography variant='body2'>Panasztételi jogával a Nemzeti Adatvédelmi és Információszabadság Hatóságnál élhet:</Typography>
				<Typography variant='body2'>Nemzeti Adatvédelmi és Információszabadság Hatóság</Typography>
				<Typography variant='body2'>Honlap: naih.hu</Typography>
				<Typography variant='body2'>1125 Budapest, Szilágyi Erzsébet fasor 22/C.</Typography>
				<Typography variant='body2'>Levelezési cím: 1530 Budapest, Postafiók: 5.</Typography>
				<Typography variant='body2'>Telefon: +36 -1-391-1400</Typography>
				<Typography variant='body2'>Fax: +36-1-391-1410</Typography>
			</Box>
		</section>
	);
};

export default DataSecurity;
