import { useEffect, useState } from 'react';
import { EDeviceForm } from '../utils/models';

interface Size {
  width: number;
  height: number;
}

export function useWindowSize(): { windowSize: Size, deviceForm: EDeviceForm } {
	const [windowSize, setWindowSize] = useState<Size>({
		width: 0,
		height: 0,
	});

	const [deviceForm, setDeviceForm] = useState<EDeviceForm>(EDeviceForm.MOBILE);

	useEffect(() => {
		function handleResize() {
			if(window.innerWidth < 901) {
				window.innerWidth < 601
					? setDeviceForm(EDeviceForm.MOBILE_XS)
					: setDeviceForm(EDeviceForm.MOBILE);
			} else {
				window.innerWidth < 1201 && window.innerWidth < 1537
					? setDeviceForm(EDeviceForm.TABLET)
					: setDeviceForm(EDeviceForm.DESKTOP);
			}
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
    
		window.addEventListener('resize', handleResize);
    
		handleResize();
    
		return () => window.removeEventListener('resize', handleResize);
	}, []);
  
	return {
		windowSize,
		deviceForm};
}
