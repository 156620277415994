import React, { useEffect, useState } from 'react';
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { EDeviceForm } from '../../../utils/models';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import PercentIcon from '@mui/icons-material/Percent';
import ListItemIcon from '@mui/material/ListItemIcon';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallIcon from '@mui/icons-material/Call';
import BusinessIcon from '@mui/icons-material/Business';
import Divider from '@mui/material/Divider';

import { Avatar } from '@mui/material';
import Logo from '../../../assets/img/aero-service-logo.png';

import styles from './header.module.scss';

enum ENavigation {
  '/',
  'akciok',
  'rolunk',
  'kapcsolat'
}

interface HeaderProps {
  device: EDeviceForm;
	scrollY: number;
}

const Header: React.FC<HeaderProps> = ({ device, scrollY }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [value, setValue] = useState(0);
	const [isSticky, setIsSticky] = useState(false);

	const navigate = useNavigate();
	const isMobile = device === EDeviceForm.MOBILE_XS;
	const isTablet = device === EDeviceForm.TABLET || device === EDeviceForm.MOBILE;
	const isDesktop = device === EDeviceForm.DESKTOP;
  
	useEffect(() => {
		navigate(ENavigation[value]);
	}, [value]);

	useEffect(() => {
		if(isTablet || isDesktop) {
			if(!isSticky && scrollY > 40) {
				setIsSticky(true);
			}
			if(isSticky && scrollY < 40) {
				setIsSticky(false);
			}
		}
	}, [scrollY]);

	const open = Boolean(anchorEl);

	const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		if(anchorEl === null) {
			setAnchorEl(event.currentTarget);
		}else {
			setAnchorEl(null);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<header>
			<div className={`${styles.topBar}`}>
				<Grid container>
					<Grid item xs={12} sm={4} md={3} lg={3}>
						<Grid container alignItems="center" justifyContent="center" spacing={2}>
							{!isTablet && <Grid item><MailOutlineIcon fontSize="small" /></Grid>}
							<Grid item><Typography className={styles.topText} variant="caption" >4033 Debrecen, Sámsoni út 166.</Typography></Grid>
						</Grid>
					</Grid>
					<Grid item xs={6} sm={2} md={3} lg={3}>
						<Grid container alignItems="center" justifyContent="center" spacing={2}>
							{!isTablet &&  <Grid item ><CallIcon fontSize="small" /></Grid>}
							<Grid item><Typography className={styles.topText} variant="caption" >+36 30 93 66 888</Typography></Grid>
						</Grid>
					</Grid>
					<Grid item xs={6} sm={2} md={3} lg={3}>
						<Grid container alignItems="center" justifyContent="center" spacing={2}>
							<Grid item><Typography className={styles.topText} variant="caption" >H-P: 8:00-16:00</Typography></Grid>
						</Grid>
					</Grid>
					{!isMobile && <Grid item xs={3} sm={4} md={3} lg={3}>
						<Grid container alignItems="center" justifyContent="center" spacing={2}>
							{!isTablet && <Grid item><BusinessIcon fontSize="small" /></Grid>}
							<Grid item><Typography className={styles.topText} variant="caption" >mako.istvan.aero@gmail.com</Typography></Grid>
						</Grid>
					</Grid>}
				</Grid>
			</div>
			{isMobile && (
				<nav className={`${styles.header} ${styles.header__mobile}`}>
					<BottomNavigation
						sx={{ backgroundColor: '#000'}}
						value={value}
						className={styles.header__bottom}
						onChange={(event, newValue) => {
							setValue(newValue);
						}}
					> 
						<BottomNavigationAction sx={{ color: 'rgb(127, 186, 187)' }} label="Kezdőlap" icon={<HomeIcon sx={{ color: 'rgb(49, 182, 185)' }} />} />
						<BottomNavigationAction sx={{ color: 'rgb(127, 186, 187)' }} label="Akciók" icon={<PercentIcon sx={{ color: 'rgb(49, 182, 185)' }} />} />
						<BottomNavigationAction sx={{ color: 'rgb(127, 186, 187)' }} label="Rólunk" icon={<InfoIcon sx={{ color: 'rgb(49, 182, 185)' }} />} />
						<BottomNavigationAction sx={{ color: 'rgb(127, 186, 187)' }} label="Kapcsolat" icon={<LocationOnIcon sx={{ color: 'rgb(49, 182, 185)' }} />} />
					</BottomNavigation>
				</nav>
			)}
			{isTablet && (
				<nav className={`${styles.header} ${styles.header__tablet}`}>
					<div className={styles.logoWrapper}>
						<Avatar
							alt="Aero szerviz logo"
							src={Logo}
							className={`${isSticky ? styles.logo__sticky : styles.logo}`}
						/>
						<div>
							<Typography variant='h2'>AERO SZERVIZ</Typography>
							<Typography variant='h3' className={`${isSticky ? styles.hidden : ''}`}>Debrecen</Typography>
						</div>
					</div>
					<Tooltip title="Menü">
						<label htmlFor="icon-button-file">
							<IconButton
								onClick={handleMenuClick} 
								color="primary"
								aria-label="upload picture"
								component="span">
								<MenuIcon sx={{ color: 'rgb(127, 186, 187)' }} />
							</IconButton>
						</label>
					</Tooltip>
					<Menu
						anchorEl={anchorEl}
						id="main-menu"
						open={open}
						onClose={handleClose}
						onClick={handleClose}
						PaperProps={{
							elevation: 0,
							sx: {
								overflow: 'visible',
								backgroundColor: '#282c34',
								filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
								mt: 1.5,
								'& .MuiAvatar-root': {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								}
							},
						}}
						
						transformOrigin={{ horizontal: 'right', vertical: 'top' }}
						anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					>
						<MenuItem>
							<Link to='/' className={styles.link}>
								<ListItemIcon sx={{ color: 'rgb(127, 186, 187)' }}>
									<HomeIcon sx={{ marginRight: '8px' }} />
									<Typography variant='button' >Kezdőlap</Typography>	
								</ListItemIcon>
							</Link>
						</MenuItem>
						<MenuItem>
							<Link to='akciok' className={styles.link}>
								<ListItemIcon sx={{ color: 'rgb(127, 186, 187)' }}>
									<PercentIcon sx={{ marginRight: '8px' }} />
									<Typography variant='button' >Akciók</Typography>
								</ListItemIcon>
							</Link>
						</MenuItem>
						<Divider />
						<MenuItem>
							<Link to='rolunk' className={styles.link}>
								<ListItemIcon sx={{ color: 'rgb(127, 186, 187)' }}>
									<InfoIcon sx={{ marginRight: '8px' }} />
									<Typography variant='button' >Rólunk</Typography>
								</ListItemIcon>
							</Link>
						</MenuItem>
						<MenuItem>
							<Link to='kapcsolat' className={styles.link}>
								<ListItemIcon sx={{ color: 'rgb(127, 186, 187)' }}>
									<LocationOnIcon sx={{ marginRight: '8px' }} />
									<Typography variant='button'>Kapcsolat</Typography>	
								</ListItemIcon>
							</Link>
						</MenuItem>
					</Menu>
				</nav>
			)}
			{isDesktop && (
				<nav className={`${styles.header} ${styles.header__desktop}`}>
					<div className={styles.logoWrapper}>
						<Avatar
							alt="Aero szerviz logo"
							src={Logo}
							className={`${isSticky ? styles.logo__sticky : styles.logo}`}
						/>
						<div>
							<Typography variant='h2'>AERO SZERVIZ</Typography>
							<Typography variant='h3' className={`${isSticky ? styles.hidden : ''}`}>Debrecen</Typography>
						</div>
					</div>
					<ul className={styles.navigation__desktop}>
						<li>
							<NavLink
								style={({ isActive }) => {
									return {
										margin: '1rem',
										color: isActive ? 'rgb(127, 186, 187)' : 'white',
										textDecoration: 'none'
									};
								}}
								to={'/'}
							>
								<Typography variant='button' >Kezdőlap</Typography>							
							</NavLink>
						</li>
						<li>
							<NavLink
								style={({ isActive }) => {
									return {
										margin: '1rem',
										color: isActive ? 'rgb(127, 186, 187)' : 'white',
										textDecoration: 'none'
									};
								}}
								to={'akciok'}
							>
								<Typography variant='button' >Akciók</Typography>						
							</NavLink>
						</li>
						<li>
							<NavLink
								style={({ isActive }) => {
									return {
										margin: '1rem',
										color: isActive ? 'rgb(127, 186, 187)' : 'white',
										textDecoration: 'none',
									};
								}}
								to={'rolunk'}
							>
								<Typography variant='button' >Rólunk</Typography>						
							</NavLink>
						</li>
						<li>
							<NavLink
								style={({ isActive }) => {
									return {
										margin: '1rem',
										color: isActive ? 'rgb(127, 186, 187)' : 'white',
										textDecoration: 'none',
									};
								}}
								to={'kapcsolat'}
							>
								<Typography variant='button'>Kapcsolat</Typography>						
							</NavLink>
						</li>
					</ul>
				</nav>
			)}
		</header>
	);
};

export default Header;
