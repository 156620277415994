import Img1 from '../assets/img/slide_1.jpg';
import Img2 from '../assets/img/slide_2.jpg';
import Img3 from '../assets/img/slide_3.jpg';
import Img4 from '../assets/img/slide_4.jpg';
import Img11 from '../assets/img/slide_5.jpg';
import Img12 from '../assets/img/slide_6.jpg';
import Img13 from '../assets/img/slide_7.jpg';
import Img14 from '../assets/img/slide_8.jpg';
import Img15 from '../assets/img/slide_9.jpg';
import Img16 from '../assets/img/slide_10.jpg';
import Img5 from '../assets/img/szolgaltatas1.jpg';
import Img6 from '../assets/img/szolgaltatas2.jpg';
import Img10 from '../assets/img/szolgaltatas3.jpg';
import Img7 from '../assets/img/szolgaltatas4.jpg';
import Img9 from '../assets/img/szolgaltatas5.jpg';
import { Product } from './models';

export const sliderImages = [
	{
		original: Img4,
		thumbnail: Img4,
		originalAlt: 'Aero service debrecen',
		thumbnailAlt: 'Aero service debrecen',
		originalTitle: 'Aero service debrecen',
		thumbnailTitle: 'Aero service debrecen',
		description: 'Aero autoszerviz Debrecen'
	},
	{
		original: Img11,
		thumbnail: Img11,
		originalAlt: 'Aero service debrecen',
		thumbnailAlt: 'Aero service debrecen',
		originalTitle: 'Aero service debrecen',
		thumbnailTitle: 'Aero service debrecen',
		description: 'Aero autoszerviz Debrecen'
	},
	{
		original: Img12,
		thumbnail: Img12,
		originalAlt: 'Aero service debrecen',
		thumbnailAlt: 'Aero service debrecen',
		originalTitle: 'Aero service debrecen',
		thumbnailTitle: 'Aero service debrecen',
		description: 'Aero autoszerviz Debrecen'
	},
	{
		original: Img2,
		thumbnail: Img2,
		originalAlt: 'Aero service debrecen',
		thumbnailAlt: 'Aero service debrecen',
		originalTitle: 'Aero service debrecen',
		thumbnailTitle: 'Aero service debrecen',
		description: 'Aero autoszerviz Debrecen'
	},
	{
		original: Img13,
		thumbnail: Img13,
		originalAlt: 'Aero service debrecen',
		thumbnailAlt: 'Aero service debrecen',
		originalTitle: 'Aero service debrecen',
		thumbnailTitle: 'Aero service debrecen',
		description: 'Aero autoszerviz Debrecen'
	},
	{
		original: Img14,
		thumbnail: Img14,
		originalAlt: 'Aero service debrecen',
		thumbnailAlt: 'Aero service debrecen',
		originalTitle: 'Aero service debrecen',
		thumbnailTitle: 'Aero service debrecen',
		description: 'Aero autoszerviz Debrecen'
	},
	{
		original: Img1,
		thumbnail: Img1,
		originalAlt: 'Aero service debrecen',
		thumbnailAlt: 'Aero service debrecen',
		originalTitle: 'Aero service debrecen',
		thumbnailTitle: 'Aero service debrecen',
		description: 'Aero autoszerviz Debrecen'
	},
	{
		original: Img15,
		thumbnail: Img15,
		originalAlt: 'Aero service debrecen',
		thumbnailAlt: 'Aero service debrecen',
		originalTitle: 'Aero service debrecen',
		thumbnailTitle: 'Aero service debrecen',
		description: 'Aero autoszerviz Debrecen'
	},
	{
		original: Img16,
		thumbnail: Img16,
		originalAlt: 'Aero service debrecen',
		thumbnailAlt: 'Aero service debrecen',
		originalTitle: 'Aero service debrecen',
		thumbnailTitle: 'Aero service debrecen',
		description: 'Aero autoszerviz Debrecen'
	},
	{
		original: Img3,
		thumbnail: Img3,
		originalAlt: 'Aero service debrecen',
		thumbnailAlt: 'Aero service debrecen',
		originalTitle: 'Aero service debrecen',
		thumbnailTitle: 'Aero service debrecen',
		description: 'Aero autoszerviz Debrecen'
	},
];

export const products: Product[] = [
	{
		title: 'Időszakos szerviz',
		img: Img10,
		description: 'Bármely tipusú gépjárműje időszakos szervizét elvégezzük, legyen az éves vagy km szerinti. Olajcsere kizárólag minőségi olajokkal és szűrőkkel, a gyári előírásoknak megfelelően.'
	},
	{
		title: 'Gépjármű átvizsgálás',
		img: Img9,
		description: 'A Gépjármű vásárlás előtti átvizsgálása és állapotfelmérése. Festékréteg vastagság mérés, műszeres diagnosztika. Fék, futómű, karosszéria teljes átvizsgálása.'
	},
	{
		title: 'Fék javítás,-ellenőrzés',
		img: Img6,
		description: 'Fékek teljes körű diagnosztikája, mechanikus hibák átvizsgálása. Féktárcsák, betétek, fékpofák cseréje illetve felújítása. ABS/ESP rendszerek diagnosztikája, javítása.Elektronikus rögzítőfékek javítása,kalibrálása.'
	},
	{
		title: 'Motordiagnosztika',
		img: Img5,
		description: 'Motor javítás a vezérműszíj,-vezérműlánc cserétől a Hengerfej felújításig, teljes felújításig, illetve Motorcseréig.'
	},
	{
		title: 'Váltó javítás',
		img: Img7,
		description: 'Mechanikus váltó, kuplung és Kettőstömegű lendítőkerék diagnosztikája,javítása.'
	}
];
