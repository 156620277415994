import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationIcon from '@mui/icons-material/LocationCity';
import Timer from '@mui/icons-material/AccessTime';

import styles from './contact.module.scss';

const Contact: React.FC = () => {
	const [showLoader, setShowLoader] = useState<boolean>(true);

	useEffect(() => {
		const timeout = setTimeout(() => setShowLoader(false), 1800);
		return () => {
			clearTimeout(timeout);
		};
	},[]);

	return (
		<div className={styles.contact}>
			<div className={styles.contentWrapper}>
				<div className={styles.leftWrapper}>
					<Typography variant='h1' className={styles.pageTitle}>KAPCSOLAT</Typography>
					<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
						<ListItem className={styles.listItem}>
							<ListItemAvatar>
								<Avatar alt="Telefon ikon" className={styles.listItemAvatar}>
									<PhoneIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText className={styles.listItemText} primary="Telefon:" secondary="+36 30 93 66 888" />
						</ListItem>
						<ListItem className={styles.listItem}>
							<ListItemAvatar>
								<Avatar alt="Email ikon" className={styles.listItemAvatar}>
									<EmailIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText className={styles.listItemText} primary="Email:" secondary="mako.istvan.aero@gmail.com" />
						</ListItem>
						<ListItem className={styles.listItem}>
							<ListItemAvatar >
								<Avatar alt="Cím ikon" className={styles.listItemAvatar}>
									<LocationIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText className={styles.listItemText} primary="Cím:" secondary="Sámsoni út 166, 4033 Debrecen" />
						</ListItem>
						<ListItem className={styles.listItem}>
							<ListItemAvatar>
								<Avatar alt="Nyitvatartási idő" className={styles.listItemAvatar}>
									<Timer />
								</Avatar>
							</ListItemAvatar>
							<ListItemText className={styles.listItemText} primary="Nyitvatartási idő:" secondary="H-P: 8.00-16.00" />
						</ListItem>
					</List>
				</div>
				<div className={styles.rightWrapper}>
					{showLoader && (
						<div className={styles.loaderWrapper}>
							<CircularProgress className={styles.loader} />
						</div>
					)}
					{!showLoader && (
						<div className={styles.mapouter}>
							<div className={styles.gmap_canvas}>
								<iframe 
									id="gmap_canvas" 
									src="https://maps.google.com/maps?q=debrecen%20s%C3%A1msoni%20%C3%BAt%20166&t=&z=15&ie=UTF8&iwloc=&output=embed"
									frameBorder={0} scrolling="yes" marginHeight={2} marginWidth={0}>
								</iframe>
							</div>
						</div>
					)}
				</div>
			</div>
			
		</div>
	);
};

export default Contact;
