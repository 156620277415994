import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Header from './library/components/header/header';
import HomePage from './library/pages/home/home';
import AboutPage from './library/pages/about/about';
import SalesPage from './library/pages/sales/sales';
import DataSecurity from './library/pages/datasecurity/datasecurity';
import ContactPage from './library/pages/contact/contact';
import * as Hooks from './utils/hooks';
import useScrollPosition from '@react-hook/window-scroll';

import { theme } from  './styles/theme';
import styles from './App.module.scss';

const App: React.FC = () => {
	const browserInfo = Hooks.useWindowSize();
	const scrollY = useScrollPosition();

	useEffect(() => {
		console.log('browserInfo', browserInfo);
	},[browserInfo]);

	return (
		<ThemeProvider theme={theme}>
			<div className={styles.App}>
				<Header device={browserInfo.deviceForm} scrollY={scrollY} />
				<main>
					<Routes>
						<Route path="/" element={<HomePage device={browserInfo.deviceForm} />} />
						<Route path="rolunk" element={<AboutPage />} />
						<Route path="akciok" element={<SalesPage />} />
						<Route path="adatvedelem" element={<DataSecurity />} />
						<Route path="kapcsolat" element={<ContactPage />} />
					</Routes>
				</main>
			</div>
		</ThemeProvider>
	);
};

export default App;
