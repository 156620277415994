import React from 'react';
import { Typography, Box } from '@mui/material';
import styles from './sales.module.scss';

const Sales: React.FC = () => (
	<div className={styles.sales}>
		<Box>
			<Typography variant='h1' className={styles.pageTitle} >Akciók</Typography>
			<Typography variant='body1'>Hamarosan...</Typography>
		</Box>
	</div>
);

export default Sales;
