import React from 'react';
import { Box, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import Img1 from '../../../assets/img/about1.jpg';
import Img2 from '../../../assets/img/about2.jpg';
import Img3 from '../../../assets/img/about3.jpg';
import Img4 from '../../../assets/img/about4.jpg';
import styles from './about.module.scss';

const AboutPage = (): JSX.Element => {
	return(
		<div className={styles.about}>
			<Box>
				<Paper className={styles.copyBlackWrapper}>
					<Typography variant='h1' className={styles.pageTitle}>Rólunk</Typography>
					<section className={styles.contentWrapper}>
						<img className={styles.image} src={Img1} alt="Aero Szerviz foto" />
						<div className={styles.textContentWrapper}>
							<Typography variant='body1'>Debreceni autószerelő műhelyünkben közel 15 éve foglalkozunk személyautók javításával. Szervizünkben nagyon nagy figyelmet fordítunk arra, hogy egy megrendelt javítás, márkaszerviz minőségben készüljön el,
								de korrekt és megfizethető áron. Ennek feltételei a minőségi alkatrész és egy és egy jól felszerelt szakműhely.
							</Typography>
			
							<List subheader="Szolgáltatásaink" className={styles.list}>
								<ListItem >
									<ListItemText className={styles.listItem} id="kotelezo-szerviz" primary="Kötelező és időszakos szerviz" />
								</ListItem>
								<ListItem >
									<ListItemText className={styles.listItem} id="olaj-szerviz" primary="Olaj és szűrőcsere" />
								</ListItem>
								<ListItem >
									<ListItemText className={styles.listItem} id="motor-valto-szerviz" primary="Motor,-váltó javítás" />
								</ListItem>
								<ListItem >
									<ListItemText className={styles.listItem} id="fek-futomu-szerviz" primary="Fék,-futómű javítás" />
								</ListItem>
								<ListItem >
									<ListItemText className={styles.listItem} id="vizsga" primary="Vizsgára felkészítést, vizsgáztatás" />
								</ListItem>
							</List>
						</div>
					</section>
				</Paper>
			</Box>
			<Box>
				<Paper className={styles.copyPrimaryWrapper} square>
					<Typography variant='h2' className={styles.pageSubtitle}>Alkatrészek és óradíj</Typography>
					<section className={styles.contentWrapper}>
						<img className={styles.image} src={Img2} alt="Auto alkatreszek" />
						<div className={styles.textContentWrapper}>
							<Typography variant="body2">
								Lehetőséget biztosítunk az ügyfél által hozott alkatrészek beépítésére is, bár itt csak az általunk végzett javításokra tudunk garanciát vállalni,
								ami ebben az esetben 6 hónap.
								Rengeteg alkatrész beszállító céggel állunk napi kapcsolatban, ezért amennyiben Önnek van egy jól bevállt partnere, az általuk biztosított
								kedvezmények akár rajtunk keresztül is érvényesíthetőek.
							</Typography>
							<Typography variant="body2">
								Mi a javításokat, szervizeket egy márkaszerviz magas óradíjaival szemben, egy barátságosabb, jóval kedvezőbb óradíjjal, 
								legalább ugyanolyan minőségben, és még talán attól is nagyobb odafigyeléssel végezzük.
							</Typography>
						</div>
					</section>
				</Paper>
			</Box>
			<Box>
				<Paper className={styles.copyBlackWrapper} square>
					<Typography variant='h2' className={styles.pageSubtitle}>Garancia</Typography>
					<section className={styles.contentWrapper}>
						<img className={styles.image} src={Img3} alt="Aero Szerviz garancia" />
						<div className={styles.textContentWrapper}>
							<Typography variant='body2'>
								A mai világban az internet rengeteg lehetőséget ajánl fel számunkra, melyek akár nagyon jól, vagy nagyon rosszul is végződhetnek. Amennyiben a szervizre bízza az alkatrész beszerzését, biztosíthatjuk, hogy valóban minőségi alkatrész kerül a gépjárműbe amire valóban szükség van,
								esetenként akár 5 év garanciával!
							</Typography>
							<Typography variant='body2'>Az általunk végzett munkára 6 hónap garanciát vállalunk minden esetben!</Typography>
						</div>
					</section>
				</Paper>
			</Box>
			<Box>
				<Paper className={styles.copyPrimaryWrapper} square>
					<Typography variant='h2' className={styles.pageSubtitle}>Valamint...</Typography>
					<section className={styles.contentWrapper}>
						<img className={styles.image} src={Img4} alt="Aero Szerviz gépjárművek diagnosztikai vizsgálata" />
						<div className={styles.textContentWrapper}>
							<Typography variant='body2'>Elvégezzük továbbá a gépjárművek diagnosztikai vizsgálatát, vásárlás előtti átvizsgálását...
								azaz teljeskörű szolgáltatással állunk ügyfeleink rendelkezésére. 
							</Typography>
							<Typography variant='body2'>A javításokat 3 beálláson, 
								elsősorban az általunk beszerzett alkatrészekkel végezzük el, legyen az eredeti (gyári alkatrész) vagy eredetivel minőségileg megegyező utángyártott alkatrész.
							</Typography>
						</div>
					</section>
				</Paper>
			</Box>
		</div>
	);};

export default AboutPage;
