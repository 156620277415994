import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Product } from '../../../utils/models';
import styles from './card.module.scss';

interface ProductCardProps {
  product: Product,
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {

	return (
		<div className={styles.cardWrapper}>
			<Card className={styles.card}>
				<CardMedia
					component="img"
					image={product.img}
					alt={product.description}
				/>
				<CardContent>
					<Typography gutterBottom variant="h5" className={styles.cardTitle}>
						{ product.title }
					</Typography>
					<Typography variant="caption" color="text.secondary">
						{ product.description }
					</Typography>
				</CardContent>
			</Card>
		</div>
	);
};

export default ProductCard;
