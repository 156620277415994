import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
	components: {
		MuiBottomNavigationAction: {
			styleOverrides: {
				label: {
					color: 'rgb(127, 186, 187)',
				},
			},
		},
	},
	palette: {
		primary: {
			main: 'rgb(127, 186, 187)'
		}
	}
});

theme.components = {
	MuiCard: {
		styleOverrides: {
			root: {
				width: '17.5rem',
				margin: '1rem',
				boxShadow: '0px 0.3125rem 0.9375rem -1px rgba(0,0,0,1)',
				background: 'linear-gradient(48deg, rgba(0,0,0,1) -60%, rgba(0,188,212,1) 50%)',
				[theme.breakpoints.up('md')]: {
					width: '16.875rem'
				},
				[theme.breakpoints.up('lg')]: {
					width: '20rem'
				},
				[theme.breakpoints.up('xl')]: {
					width: '21.25rem'
				},
			},
		},
	},
	MuiCardContent: {
		styleOverrides: {
			root: {
				padding: '1rem',
				position: 'absolute',
				top: '70%',
				left: '0',
				right: '0',
				bottom: '0',
				backgroundColor: 'rgba(127,186,187,.6)',
				transition: 'all 700ms ease-in-out',
				[theme.breakpoints.up('lg')]: {
					padding: '2rem'
				},
				':hover': {
					top: '0',
					bottom: '0',
					backgroundColor: 'rgba(127,186,187,.9)',
				}
			},
		},
	},
	MuiAvatar: {
		styleOverrides: {
			circular: {
				width: '5.875rem',
				height: '5.875rem',
				marginRight: '1rem',
				transition: 'height .5s, width .5s',
				[theme.breakpoints.up('lg')]: {
					width: '7.75rem',
					height: '7.75rem',
				},
			}
		}
	},
	MuiListItemIcon: {
		styleOverrides: {
			root: {
				display: 'flex',
				alignItems: 'center'
			}
		}
	},
	MuiMenuItem: {
		styleOverrides: {
			root: {
				margin: '0px',
				padding: '0.5rem 1rem'
			}
		}
	}
};

theme.typography.caption = {
	fontSize: '.75rem',
	[theme.breakpoints.up('sm')]: {
		fontSize: '.9rem',
	},
};

theme.typography.h1 = {
	fontSize: '2rem',
	margin: '1rem 0rem',
	fontWeight: 'lighter',
	[theme.breakpoints.up('sm')]: {
		fontSize: '3rem',
	},
	[theme.breakpoints.up('md')]: {
		fontSize: '3rem',
	},
	[theme.breakpoints.up('lg')]: {
		fontSize: '4rem',
	},
};

theme.typography.h2 = {
	fontSize: '2rem',
	margin: '0rem',
	fontWeight: 'lighter',
	[theme.breakpoints.up('sm')]: {
		fontSize: '2rem',
	},
	[theme.breakpoints.up('md')]: {
		fontSize: '2rem',
	},
	[theme.breakpoints.up('lg')]: {
		fontSize: '3rem',
	},
};

theme.typography.h3 = {
	fontSize: '1rem',
	margin: '0rem',
	fontWeight: 'lighter',
	textAlign: 'left',
	[theme.breakpoints.up('sm')]: {
		fontSize: '1rem',
	},
	[theme.breakpoints.up('md')]: {
		fontSize: '1rem',
	},
	[theme.breakpoints.up('lg')]: {
		fontSize: '1rem',
	},
};

theme.typography.body1 = {
	fontSize: '1.2rem',
	margin: '1rem',
	fontWeight: 'lighter',
	textAlign: 'center',
};

theme.typography.body2 = {
	fontSize: '1rem',
	margin: '1rem',
	textAlign: 'left',
};

theme.typography.button = {
	fontSize: '1rem',
	margin: '0rem',
	fontWeight: 'lighter',
	textAlign: 'left',
	[theme.breakpoints.up('sm')]: {
		fontSize: '1rem',
	},
	[theme.breakpoints.up('md')]: {
		fontSize: '1rem',
	},
	[theme.breakpoints.up('lg')]: {
		fontSize: '2rem',
	},
};
