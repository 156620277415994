export enum EDeviceForm {
  MOBILE_XS = 'MOBILE_XS',
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
}

export interface Product {
  title: string,
  img: string,
  description: string
}